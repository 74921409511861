import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import queryString from 'qs'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'

import {Card, Heading, Paragraph, Flex, Divider, Image} from 'reakit'
import { Form,  MobileHeader } from '../components/layouts'
import Layout from '../components/layout'
import { Link, Button } from '../components/ui'
import theme from '../theme/content'

const content = theme.pagesContent.mobileDonationFlow

// import Actions
import {setDonationType, getAccounts, unauthConfigDonations,
	exchangeToken, setDonationAccount} from '../factory'

class SelectDonation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			otpToken: '',
			token: '',
			dailyDonation: 40,
			dailyDonationActive: false,
			noAccount: false,
			showInfo: false,
			accountSet: -1,
			account_id: -1,
			option: 0,
			accounts: [],
			chosenAccount: false
		}
		this.setParams = this.setParams.bind(this)
		this.setAccountList = this.setAccountList.bind(this)
		this.setChosenAccount = this.setChosenAccount.bind(this)
		this.filterAccounts = this.filterAccounts.bind(this)
		this.setDonationAccount = this.setDonationAccount.bind(this)
	}

	componentDidMount() {
		this.setParams()
		this.setAccountList(this.props.accounts)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.accountsRecieved) {
			this.setAccountList(nextProps.accounts)
		}
		if (nextProps.accounts && !this.state.accountSet &&
		  nextProps.accounts.filter(
		  	acc => acc.is_payment && acc.id).length > 0) {
			this.setChosenAccount()
		}
		if (nextProps.token && nextProps.accounts && nextProps.accounts.length === 0) {
			nextProps.getAccounts(nextProps.token)
		}
	}


	setAccountList(account_list) {
		const accountMap = account_list.map((acc) => acc.is_chargable &&
											{text: `${acc.name}... ${acc.mask}`, value: acc.id})
		const accounts = accountMap.filter(acc => acc && acc)

		const onlyCC = account_list.length === account_list.filter((acc) => acc.plaid_account_id === null).length

		this.setState({accounts, option: onlyCC ? 3 : this.state.option, onlyCC})

		const chosenAccount = account_list.filter(acc => acc.is_payment && acc)
		if (chosenAccount.length > 0) {
			this.setState({accountSet: chosenAccount[0].id, account_id: chosenAccount[0].id, chosenAccount: true})
		} else {
			this.setState({accounts: [{text: '-', value: -1}].concat(accounts), accountSet: -1})
		}
	}

	setChosenAccount() {
		this.setState({accountSet: true})
	}

	setParams() {
		// isolate variables from url
		const query = queryString.parse(location.search.slice(1))
		this.props.unauthConfigDonations(query.token, query.key)
		this.setState({redirect: query.redirect})
	}

	setDonationPreferences() {
		const daily = this.state.dailyDonationActive
			? this.state.dailyDonation / 100
			: 0
		const params = {}
		params.donor_type = this.state.option
		params.daily_donation_sum = daily
		this.props.setDonationType(params, this.props.token)

		const newLink = this.state.redirect || content.success.buttonLink
		window.location.href = newLink
	}

	setDonationAccount(e) {
		this.props.setDonationAccount(e.target.value, true, this.props.token)
		this.setState({account_id: e.target.value})
	}

	renderOptions() {
		const {onlyCC} = this.state
		return (
			<Layout>
				<MobileHeader
					backLink={this.state.redirect || 'flourish://'}
					title={'Donation Setup'}
					infoClick={() => this.setState({showInfo: true})}
				/>
				<Flex
					width={'100%'}
					alignItems={'center'}
					justifyContent={'center'}
					column
				>
					<Heading as="h4" margin="1em 0.5rem 0 0" textAlign="center">
						How Do You Want to Give?
					</Heading>
				</Flex>
				{onlyCC &&
				<Flex
					width={'100%'}
					alignItems={'center'}
					justifyContent={'center'}
					column
				>
					<Paragraph
						color={theme.colors.dustGray}
						textAlign="center"
						width={'80%'}
						margin="1em 0.5rem 0 0"
					>
					You have only linked your credit card, so you have to give via a daily donation. If you would like to give in another form, please link a bank card by signing into your bank from your profile screen in the app.
					</Paragraph>
				</Flex>
				}

				{!onlyCC && <div>
					<Flex
						width={'90%'}
						alignItems={'center'}
						justifyContent={'center'}
						margin={'1em auto'}
						column
					>
						<Card
							onClick={() =>
								this.setState({
									option: 2,
									dailyDonationActive: true,
									noAccountModal: this.state.noAccount,
								})
							}
							boxShadow="0px 2px 4px 0px rgba(74, 74, 74, 0.3)"
							cursor="pointer"
						>
							<Flex
								column
								alignItems={'center'}
								justifyContent={'center'}
							>
								<Image
									height={64}
									width={'auto'}
									margin={'0 auto'}
									marginBottom={0}
									src={content.both.icon}
								/>
								<Heading as="h4" textAlign="center">
								Roundups + Daily Donation
								</Heading>
								<Paragraph
									textAlign={'center'}
									color={theme.colors.dustGray}
								>
								Round up your transactions to the nearest dollar
								and give a set amount each day
								</Paragraph>
							</Flex>
						</Card>
					</Flex>
					<Flex
						width={'90%'}
						alignItems={'center'}
						justifyContent={'center'}
						margin={'1em auto'}
						column
					>
						<Card
							onClick={() =>
								this.setState({
									option: 1,
									noAccountModal: this.state.noAccount,
								})
							}
							boxShadow="0px 2px 4px 0px rgba(74, 74, 74, 0.3)"
							cursor="pointer"
						>
							<Flex
								column
								alignItems={'center'}
								justifyContent={'center'}
							>
								<Image
									height={64}
									width={'auto'}
									margin={'0 auto'}
									marginBottom={0}
									src={content.roundUp.icon}
								/>
								<Heading as="h4" textAlign="center">Roundups</Heading>
								<Paragraph
									textAlign={'center'}
									color={theme.colors.dustGray}
								>
								Round up your transactions to the nearest dollar
								and make a huge impact
								</Paragraph>
							</Flex>
						</Card>
					</Flex>
				</div>
				}
				<Flex
					width={'90%'}
					alignItems={'center'}
					justifyContent={'center'}
					margin={'1em auto'}
					column
				>
					<Card
						onClick={() =>
							this.setState({
								option: 3,
								dailyDonationActive: true,
								noAccountModal: this.state.noAccount,
							})
						}
						boxShadow="0px 2px 4px 0px rgba(74, 74, 74, 0.3)"
						cursor="pointer"
						marginBottom={100}
					>
						<Flex
							column
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Image
								height={64}
								width={'auto'}
								margin={'0 auto'}
								marginBottom={0}
								src={content.daily.icon}
							/>
							<Heading as="h4" textAlign="center">Daily Donation</Heading>
							<Paragraph
								textAlign={'center'}
								color={theme.colors.dustGray}
							>
								Give a little bit each day and watch your impact
								grow
							</Paragraph>
						</Flex>
					</Card>
				</Flex>
			</Layout>
		)
	}

	renderChoice() {
		if (this.state.option === 2) {
			return (
				<Flex
					column
					style={{padding: '8vw'}}
					alignItems={'center'}
					justifyContent={'center'}
					margin={'1em auto'}
					column
				>
					<Card
						onClick={() =>
							this.setState({
								option: 2,
								dailyDonationActive: true,
								noAccountModal: this.state.noAccount,
							})
						}
						boxShadow="none"
						width={'100%'}
					>
						<Flex
							column
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Image
								height={64}
								width={'auto'}
								margin={'0 auto'}
								marginBottom={0}
								src={content.both.icon}
							/>
							<Heading as="h4" textAlign="center">{content.both.title}</Heading>
							<Paragraph
								textAlign={'center'}
								color={theme.colors.dustGray}
							>
								{content.both.body}
							</Paragraph>
						</Flex>
					</Card>
					<Divider
						width={'60%'}
						borderColor={theme.colors.lightGray}
					/>
					<h1 className="dailyDonation">
						$
						{parseFloat(
							Math.round(this.state.dailyDonation * 100) / 10000
						).toFixed(2)}
					</h1>
					<Slider
						trackStyle={{backgroundColor: '#fedf90', height: 10}}
						handleStyle={{
							backgroundColor: '#ffcc33',
							borderColor: '#ffcc33',
							height: 20,
							width: 20,
						}}
						style={{
							width: '75%',
							marginTop: 10,
							height: 25,
							marginBottom: 20,
							paddingTop: 5,
						}}
						step={10}
						min={30}
						max={200}
						defaultValue={this.state.dailyDonation}
						onChange={v => this.setState({dailyDonation: v})}
					/>
					<Heading as="h5" margin={0} textAlign="center">
						{content.sliderSubtitle}
					</Heading>
					<Divider
						width={'60%'}
						borderColor={theme.colors.lightGray}
					/>
				</Flex>
			)
		}

		if (this.state.option === 1) {
			return (
				<Flex
					column
					style={{padding: '8vw'}}
					alignItems={'center'}
					justifyContent={'center'}
					margin={'1em auto'}
					column
				>
					<Card
						onClick={() =>
							this.setState({
								option: 1,
								dailyDonationActive: true,
								noAccountModal: this.state.noAccount,
							})
						}
						boxShadow="none"
						width={'100%'}
					>
						<Flex
							column
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Image
								height={64}
								width={'auto'}
								margin={'0 auto'}
								marginBottom={0}
								src={content.roundUp.icon}
							/>
							<Heading as="h4" textAlign="center">{content.roundUp.title}</Heading>
							<Paragraph
								color={theme.colors.dustGray}
								textAlign="center"
							>
								{content.roundUp.body}
							</Paragraph>
						</Flex>
					</Card>
					<Divider
						width={'60%'}
						borderColor={theme.colors.lightGray}
					/>
				</Flex>
			)
		}

		return (
			<Flex
				column
				style={{padding: '8vw'}}
				alignItems={'center'}
				justifyContent={'center'}
				margin={'1em auto'}
				column
			>
				<Card
					onClick={() =>
						this.setState({
							option: 1,
							dailyDonationActive: true,
							noAccountModal: this.state.noAccount,
						})
					}
					boxShadow="none"
					width={'100%'}
				>
					<Flex
						column
						alignItems={'center'}
						justifyContent={'center'}
					>
						<Image
							height={64}
							width={'auto'}
							margin={'0 auto'}
							marginBottom={0}
							src={content.daily.icon}
						/>
						<Heading as="h4" textAlign="center">{content.daily.title}</Heading>
						<Paragraph
							color={theme.colors.dustGray}
							textAlign="center"
							width={'80%'}
						>
							{this.state.onlyCC ?
								"Because you have only linked your credit card, you have to give via a daily donation. If you would like to give in another form, please link a bank card by signing into your bank from your profile screen in the app."
								: content.daily.body}
						</Paragraph>
					</Flex>
				</Card>
				<Divider width={'60%'} borderColor={theme.colors.lightGray} />
				<h1 className="dailyDonation">
					$
					{parseFloat(
						Math.round(this.state.dailyDonation * 100) / 10000
					).toFixed(2)}
				</h1>
				<Slider
					trackStyle={{backgroundColor: '#fedf90', height: 10}}
					handleStyle={{
						backgroundColor: '#ffcc33',
						borderColor: '#ffcc33',
						height: 20,
						width: 20,
					}}
					style={{
						width: '75%',
						marginTop: 10,
						height: 25,
						marginBottom: 20,
						paddingTop: 5,
					}}
					step={10}
					min={30}
					max={200}
					defaultValue={this.state.dailyDonation}
					onChange={v => this.setState({dailyDonation: v})}
				/>
				<Heading as="h5" margin={0} textAlign="center">
					{content.sliderSubtitle}
				</Heading>
				<Divider width={'60%'} borderColor={theme.colors.lightGray} />
			</Flex>
		)
	}


	filterAccounts(acc) {
		// eslint-disable-line
		if (acc.id === Number(this.state.account_id)) {
			return acc
		}
		if (acc.is_payment) {
			return acc
		}
		return null
	}

	render() {
		const chosenAccount = this.props.accounts && this.props.accounts.filter((acc) => this.filterAccounts(acc))

		if (this.state.option > 0) {
			return (
				<Layout>
					<MobileHeader
						backClick={() =>
							this.setState({
								option: -1,
								dailyDonationActive: false,
							})
						}
						title={content.title}
						infoClick={() => this.setState({showInfo: true})}
					/>
					<Flex
						column
						alignItems={'center'}
						justifyContent={'center'}
					>
						{this.renderChoice()}

						<Button onClick={() => this.setDonationPreferences()} style={{margin: 'auto', maxWidth: '70%'}} >
							{content.success.button}
						</Button>
						{ (chosenAccount && chosenAccount.length > 0) &&
            <Paragraph style={{width: '60%', margin: 'auto', textAlign: 'center', marginTop: '1em'}}>
            	{`You'll`} be donating using your <span style={{fontWeight: 800}}>{chosenAccount[0].name} ...{chosenAccount[0].mask}</span></Paragraph>
						}
					</Flex>
				</Layout>
			)
		}
		return (
			<div className="donationSetup">
				{this.renderOptions()}
			</div>
		)
	}
}

const mapStateToProps = ({userState}) => {
	return {
		token: userState.token,
		accounts: userState.accountList,
		accountsRecieved: userState.accountsRecieved,
		error: userState.error,
		pending: userState.pending
	}
}
// selectDonationType: (dailyDonation, token) => dispatch(selectDonationType(dailyDonation, token)),

const mapDispatchToProps = (dispatch) => {
	return {
		exchangeToken: (token, key) => dispatch(exchangeToken(token, key)),
		getAccounts: (token) => dispatch(getAccounts(token)),
		unauthConfigDonations: (token, key) => dispatch(unauthConfigDonations(token, key)),
		setDonationAccount: (id, value, token) => dispatch(setDonationAccount(id, value, token)),
		setDonationType: (params, token) => dispatch(setDonationType(params, token))
	}
}

SelectDonation.propTypes = {
	location: PropTypes.object,
	exchangeToken: PropTypes.func,
	token: PropTypes.string,
	accounts: PropTypes.array,
	selectDonationType: PropTypes.func,
	setDonationAccount: PropTypes.func,
	getAccounts: PropTypes.func,
	unauthConfigDonations: PropTypes.func,
	setDonationType: PropTypes.func,
	accountsRecieved: PropTypes.bool,
	error: PropTypes.string,
	pending: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDonation)
